<template>
  <!-- wallets-spot/现货 -->
  <div class="position-history-wrap">
    <div v-if="requestedStatus === 'requested'&& list.length">
      <div class="item-box padding-top16 padding-bottom16" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
        <div class="ub ub-btw">
          <div class="ub">
            <div class="font14 main-text-1 font-weight500">{{ item.symbol }}</div>
            <div class="font14 main-text-3 font-weight400 margin-left4">Perpetual</div>
          </div>
          <div class="ub ub-cend">
            <div>
            <div class="font12 main-text-3 font-weight400">{{ item.trade_time }}</div>
            </div>
              <div class="ub ub-cen">
                <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
              </div>
            </div>
        </div>
        <div class="ub ub-btw">
          <div>
            <div v-if="item.side === 'BUY'" class="up-color">
              {{item.pos_side === 'LONG'?$t('history.openLong'):$t('history.closeShort')}}
            </div>
            <div v-if="item.side === 'SELL'" class="down-color">
              {{item.pos_side === 'LONG'?$t('history.closeLong'):$t('history.openShort')}}
            </div>
          </div>
          <div class="up-bg-color up-color">
            {{$t('history.filled')}}
          </div>
        </div>
        <div class="ub ub-btw margin-top8">
          <div class="font12 main-text-3 font-weight400">
            {{$t('history.amount')}} <span v-if="item.symbol">({{ item.symbol.split('U')[0] }})</span>
          </div>
          <div>
            <span>{{ item.amount }}</span> /
            <span class="font12 main-text-3 font-weight400">{{ item.amount }}</span>
          </div>
        </div>
        <div class="ub ub-btw margin-top4">
          <div class="font12 main-text-3 font-weight400">
            {{$t('history.price')}}
          </div>
          <div>
            <span>{{ item.price }}</span> /
            <span class="font12 main-text-3 font-weight400"> 0.000</span>
          </div>
        </div>
        <!-- <div class="ub ub-btw margin-top4">
          <div class="font12 main-text-3 font-weight400">
            {{$t('history.reduceOnly')}}
          </div>
          <div>
            True
          </div>
        </div> -->
      </div>
    </div>
    <NoData :refreshBtn="true"  @refresh="refreshFn" :tip="'No Data'" v-if="requestedStatus === 'requested'&& !list.length"> </NoData>
  </div>
</template>
<script>
import { orderList } from '@/api/trade.js'
import NoData from '@/components/market/noData'
export default {
  name: 'PositionHistory',
  components: { NoData },
  computed: {
  },
  data () {
    return {
      requestedStatus: 'pending',
      list: []
    }
  },
  mounted () {
    this.orderListFn()
  },
  methods: {
    refreshFn () {
      this.orderListFn()
    },
    orderListFn () {
      orderList({
        order_status: '',
        position_status: '15'
      }).then(res => {
        this.requestedStatus = 'requested'
        const resData = res.data
        if (resData) {
          this.list = resData.sort((a, b) => {
            const aTimeValueOf = new Date(a.trade_time).valueOf()
            const bTimeValueOf = new Date(b.trade_time).valueOf()
            return bTimeValueOf - aTimeValueOf
          })
        }
        console.log(res, 'res----fundBillList')
        this.$toast.clear()
      })
    },
    goDetail (item) {
      this.$router.push({
        path: '/history/orderDetail/' + item.id
      })
    },
    fundBillListFn () {
    }
  }
}
</script>
<style lang="less">
.position-history-wrap{
  height: calc(100vh - 360px);
  padding: 30px;
  box-sizing: border-box;
  .up-color{
    color: var(--success-color);
  }
  .down-color{
    color: #F5465C;
  }
  .up-bg-color{
    padding: 0 4px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: rgba(45, 189, 133, 0.12);
    // background-color: rgba(0, 255, 0, 0.1);
  }
  .item-box{
    box-sizing: border-box;
    border-bottom: 1px solid #ECEAEF;
  }
}
</style>
