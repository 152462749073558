<template>
  <!-- wallets-futures/合约 -->
  <div class="trade-history-wrap">
    <van-nav-bar :border="false" left-text="" z-index="99" :fixed="true" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="title">
       {{ $t('history.futuresHistory') }}
      </div>
    </van-nav-bar>
    <div class="history-tab-wrap">
      <van-tabs v-model="active">
        <van-tab v-for="(item,index) in tabList" :key="index" :title="$t(item.title)">
          <div class="components-wrap">
            <components :is="item.component"></components>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
// /history/futures/orders
// import OrdersHistory from './orders/index.vue'
import PositionHistorry from './position/index.vue'

export default {
  name: 'HistoryFutures',
  // OrdersHistory
  components: { PositionHistorry },
  computed: {
    tabType () {
      return this.$route.params.type || 'orders'
    }
  },
  props: {
  },
  data () {
    return {
      active: 0,
      tabKeyList: ['orders', 'position'],
      tabList: [
        // {
        //   title: 'history.ordersHistory',
        //   component: OrdersHistory
        // },
        {
          title: 'history.positionHistorry',
          component: PositionHistorry
        }
      ]
    }
  },
  mounted () {
    this.active = this.tabKeyList.indexOf(this.tabType)
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.trade-history-wrap{
  background-color: #fff;
  .history-tab-wrap{
    padding-top: 100px;
    .components-wrap{
    }
    .van-tabs__wrap{
      padding: 0 30px;
      border-bottom: 1px solid #ECEAEF;
      .van-tab{
        flex:none;
        margin-right: 48px;
      }
      .van-tabs__line{
        background-color: var(--line-gradient-start-color);
      }
    }
  }
}
</style>
